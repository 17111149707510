import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import EntityPageActionHandler from "components/ActionHandler/EntityPageActionHandlerAsync";
import Timeline from "components/Timeline/TimelineAsync";
import TimelineSkeleton from "components/Timeline/TimelineSkeleton";
import FeedToggle from "pages/Homepage/HomepageFeed/FeedToggle";

import HomepageSidebarInFeed from "../HomepageSidebar/HomepageSidebarInFeed";
import HomepageFeedLoggedOut from "./HomepageFeedLoggedOutAsync";
import HomepageFeedNotFollowingAnything from "./HomepageFeedNotFollowingAnythingAsync";
import HomepageFeedNothingMoreToLoad from "./HomepageFeedNothingMoreToLoadAync";

import { selectAuthLoggingIn } from "selectors/auth";
import {
  selectActivities,
  selectTimeline,
  selectTimelineLoading,
} from "selectors/timeline";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import PageStyles from "styles/PageStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...PageStyles,
  timelineFullWidth: {
    width: "100%",
    paddingRight: 0,
    paddingLeft: 0,
    marginLeft: 0,

    [ScreenSizes.lgAndAbove]: {
      // these are a bit annoying, could do with not using the mainColumn class
      width: "100%",
      paddingRight: 0,
      paddingLeft: 0,
      marginLeft: 0,
      overflow: "visible",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "100%",
      paddingRight: 0,
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
  myFeedDescription: {
    ...gStyles.avalonSemiBold,
    textAlign: "center",
    boxShadow: "0 4px 10px rgba(0,0,0,.05)",
    borderRadius: 6,
    background: "#fff",
    fontSize: "1rem",
    lineHeight: "23px",
    color: colours.black,
    width: "100%",
    padding: "1rem 2rem",
    marginBottom: "0.75rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "2rem",
      padding: "1.4rem 3rem",
    },
  },
  timelineHeadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    fontSize: "0.8125rem",

    [ScreenSizes.smAndAbove]: {
      flexDirection: "row",
      padding: "0 1.5rem",
    },
    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.9375rem",
    },
    [ScreenSizes.lgAndAbove]: {
      padding: 0,
      marginTop: "-1rem",
      fontSize: "1rem",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "max-content",
      right: 0,
      top: "-3.7em",
    },
  },
  timelineHeading: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    display: "none",
    margin: 0,
    padding: 0,

    [ScreenSizes.smAndAbove]: {
      display: "block",
    },
  },
  toggles: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    margin: 0,
    padding: 0,

    ":only-child": {
      [ScreenSizes.lgAndAbove]: {
        marginLeft: 0,
      },
    },
  },
  loading: {
    height: "219rem",

    [ScreenSizes.lgAndAbove]: {
      height: "250rem",
    },
  },
};

const HomepageFeed = (props) => {
  const { mobile, user, currentTab, getTimelineKey } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const tablet = isWindowSizeOrLess("large");

  const timelineKey = useMemo(
    () => getTimelineKey(currentTab),
    [getTimelineKey, currentTab]
  );

  const timelineLoading = useReduxState(
    (state) => {
      if (timelineKey) {
        return selectTimelineLoading(state, timelineKey);
      }

      return false;
    },
    [timelineKey]
  );

  const activities = useReduxState(
    (state) => {
      if (timelineKey) {
        return selectActivities(state, timelineKey);
      }

      return null;
    },
    [timelineKey]
  );

  const timeline = useReduxState(
    (state) => {
      if (timelineKey) {
        return selectTimeline(state, timelineKey);
      }

      return null;
    },
    [timelineKey]
  );

  const notLoggedIn = !user;
  const loggingIn = useReduxState((state) => selectAuthLoggingIn(state), []);
  const notFollowingAnything =
    !notLoggedIn &&
    timeline &&
    !timelineLoading &&
    activities &&
    activities.size === 0;
  const followingAnything = !notFollowingAnything;
  const followsEnoughStuff = user && user.get("follow_count") > 5;

  const renderMyFeedDescription = () => (
    <div className={css(styles.myFeedDescription)}>
      Your personalized Feed is curated based on the Podcasts, Creators, Users,
      and Lists that you Follow.
    </div>
  );

  const renderNothingMoreToLoad = useCallback(
    () => <HomepageFeedNothingMoreToLoad timelineKey={timelineKey} />,
    [timelineKey]
  );

  const renderTimeline = () => {
    if (currentTab.myFeed && loggingIn) {
      return <TimelineSkeleton key="HomepageFeed" />;
    }

    if (currentTab.myFeed && notLoggedIn) {
      return <HomepageFeedLoggedOut user={user} mobile={mobile} />;
    }

    if (currentTab.myFeed && notFollowingAnything) {
      return (
        <HomepageFeedNotFollowingAnything
          timelineKey={timelineKey}
          user={user}
          mobile={mobile}
        />
      );
    }

    if (currentTab.sidebar) {
      return <HomepageSidebarInFeed user={user} mobile={mobile} />;
    }

    return (
      <Timeline
        timelineKey={timelineKey}
        myFeed={currentTab.myFeed}
        mobile={tablet}
        pageType="HomepageFeed"
        renderNothingMoreToLoad={renderNothingMoreToLoad}
        aggregated
      />
    );
  };

  const renderMyFeedToggles = () => (
    <ul className={css(styles.toggles)}>
      {currentTab.toggles.map((toggle, index) => (
        <FeedToggle key={toggle ? toggle.key : index} toggle={toggle} />
      ))}
    </ul>
  );

  return (
    // use the currentTab to choose between main and personal feeds
    <div className={css(styles.mainColumn, styles.timelineFullWidth)}>
      <EntityPageActionHandler pageType="homePage" />
      {followsEnoughStuff && currentTab.toggles && (
        <div className={css(styles.timelineHeadingContainer)}>
          {followsEnoughStuff && currentTab.toggles && renderMyFeedToggles()}
        </div>
      )}
      {user &&
        currentTab.myFeed &&
        followingAnything &&
        !followsEnoughStuff &&
        renderMyFeedDescription()}
      {renderTimeline()}
    </div>
  );
};

HomepageFeed.propTypes = {
  getTimelineKey: PropTypes.func.isRequired,
  currentTab: PropTypes.object,
  user: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

HomepageFeed.defaultProps = {
  currentTab: null,
  user: null,
  mobile: false,
};

export default memo(HomepageFeed);
