import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import generateTransition from "utils/generateTransition";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const toggleWidth = 42;
const toggleHeight = 22;
const toggleGap = 2;

const baseStyles = {
  toggle: {
    margin: 0,
    padding: 0,
  },
  toggleLink: {
    ...gStyles.avalonBold,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    transition: generateTransition({ targets: ["color"] }),
    color: "var(--color-neutral-d4)",
    ":focus": {
      color: "var(--color-neutral-d3)",
    },
    ":active": {
      color: "var(--color-neutral-d3)",
    },
  },
  toggleLinkActive: {
    color: colours.black,
  },
  toggleButton: {
    display: "block",
    background: "var(--color-neutral-l4)",
    transition: generateTransition({ targets: ["background"] }),
    borderRadius: "2rem",
    width: toggleWidth,
    height: toggleHeight,
    marginRight: "1rem",
  },
  toggleButtonActive: {
    background: colours.selected,
  },
  toggleButtonIcon: {
    display: "block",
    width: toggleHeight - toggleGap * 2,
    height: toggleHeight - toggleGap * 2,
    margin: `${toggleGap}px 0 0 ${toggleGap}px`,
    borderRadius: "2rem",
    background: "#fff",
    transform: "translateX(0)",
    transition: generateTransition({ targets: "translate" }),
  },
  toggleButtonIconActive: {
    transform: `translateX(${
      toggleWidth - toggleGap * 2 - (toggleHeight - toggleGap * 2)
    }px)`,
  },
};

function FeedToggle(props) {
  const { toggle } = props;
  const { styles } = useStyles(baseStyles, props);

  const location = useLocation();

  const isCurrent = toggle.onPath === location.pathname;

  const handleToggleClick = useCallback(() => {
    sendGAEvent({
      action: "feedToggleClick",
      to: isCurrent ? toggle.offPath : toggle.onPath,
    });

    if (toggle.onClick) {
      toggle.onClick();
    }
  }, [isCurrent, toggle]);

  const to = useMemo(
    () => ({
      ...location,
      pathname: isCurrent ? toggle.offPath : toggle.onPath,
    }),
    [location, toggle, isCurrent]
  );

  return (
    <li className={css(styles.toggle, isCurrent && styles.toggleActive)}>
      <Link
        onClick={handleToggleClick}
        to={to}
        className={css(styles.toggleLink, isCurrent && styles.toggleLinkActive)}
      >
        <span
          className={css(
            styles.toggleButton,
            isCurrent && styles.toggleButtonActive
          )}
        >
          <span
            className={css(
              styles.toggleButtonIcon,
              isCurrent && styles.toggleButtonIconActive
            )}
          />
        </span>
        {toggle.label}
      </Link>
    </li>
  );
}

FeedToggle.propTypes = {
  toggle: PropTypes.object.isRequired,
};

export default memo(FeedToggle);
