import { css } from "aphrodite";
import { Fragment } from "react";

import Col from "components/Common/Col";
import { Grid, Row } from "components/Common/FlexGrid";

import ListPageHelmet from "../../Common/ListPageHelmetAsync";
import HomepageSidebarInFeed from "../../Homepage/HomepageSidebar/HomepageSidebarInFeed";

import { getBaseUrl } from "constants/base";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ListPageStyles from "styles/ListPageStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...ListPageStyles,
  contentContainer: {
    ...gStyles.contentContainer,
  },
  contentInner: {
    [ScreenSizes.mdAndBelow]: {
      marginBottom: "2rem",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 20,
  },
  sectionTitle: {
    ...gStyles.mainSectionTitle,
  },
  title: {
    ...gStyles.archivePageTitle,
  },
  episode: {
    position: "relative",
    overflow: "hidden",
    // height: 110,
    marginTop: 10,
    marginBottom: 10,
  },
  sidebarImg: {
    width: "100%",
    height: "auto",
  },
  sidebarTitleSpacer: {
    marginTop: "6.8em",
  },
  sidebarSection: {
    ...gStyles.sidebarSection,
  },
  filters: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
};

const Everything = (props) => {
  const { styles } = useStyles(baseStyles, props);

  const path = "/discover";
  const title = "Discover Podcasts and Podcast Episodes";
  const description =
    "Discover new podcasts and episodes based on what's trending worldwide.";
  const canonicalUrl = `${getBaseUrl()}${path}`;

  return (
    <Fragment>
      <ListPageHelmet
        title={title}
        url={canonicalUrl}
        description={description}
      />

      <Grid className={css(styles.contentContainer)}>
        <Row className={css(styles.contentRow)}>
          <Col xs={12} className={css(styles.columnContainer)}>
            <div className={css(styles.contentCol, styles.mainColumn)}>
              <div className={css(styles.contentInner)}>
                <HomepageSidebarInFeed></HomepageSidebarInFeed>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    </Fragment>
  );
};

export default Everything;
